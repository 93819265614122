import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const Mandate = ({ data }) => {
  const { section2Content, links, membershipSmallDisclaimer } = data

  return (
    <div className="mandate_section">
      <div className="container">
        <div className="mandate_block">
          <div className="mandate_data">
            <div className="mandate_inner_data">
              <p>{renderRichText(section2Content)}</p>
            </div>
            <div className="mandate_list">
              <p>
                <small style={{ fontSize: "12px" }}>
                  {membershipSmallDisclaimer}
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="quick_tab_section_fake"></div>
        <div className="quick_tab_section">
          <div className="container">
            <div className="quick_slidemenu">
              <div className="quick_tab_inner">
                <div className="quick_tab_title">
                  <div className="title_red_line">
                    <span>JOIN COMMB</span>
                  </div>
                </div>
                <div className="wrapall_tab" style={{ padding: "0px" }}>
                  <div className="all_quick_tabs">
                    {links.map(link => (
                      <a href={link.url}>
                        <div className="tab_btn">
                          <span>{link.label}</span>
                        </div>
                      </a>
                    ))}
                    {/* <a href="#ooh">
                      <div className="tab_btn">
                        <span>OOH Company</span>
                      </div>
                    </a>
                    <a href="#agency-advertiser">
                      <div className="tab_btn">
                        <span>Agency/Advertiser</span>
                      </div>
                    </a>
                    <div
                      className="tab_btn"
                      onClick={() => scroll.scrollTo(2250)}
                    >
                      <span>Programmatic/Re-Marketer</span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mandate
