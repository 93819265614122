import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import Hero from "../components/membership/hero"
import Join from "../components/membership/join"
import Mandate from "../components/membership/mandate"
import Testimonial from "../components/membership/testimonial"

const Membership = ({ data }) => (
  <div className="membershipPage">
    <Layout>
      <Seo title="Membership" description="COMMB is the national not-for-profit organization for the Canadian out-of-home (OOH) industry. Our membership base is comprised of advertisers, agencies, programmatic tech-stacks and OOH companies, large and small." />
      <div className="main_content_wrapper membership_page">
        <Hero data={data.contentfulMembership} />
        <Mandate data={data.contentfulMembership} />
        <Join data={data.contentfulMembership} />
        <Testimonial data={data.contentfulMembership} />
      </div>
    </Layout>
  </div>
)

export default Membership

export const query = graphql`
  query Membership($language: String) {
    contentfulMembership(node_locale: { eq: $language }) {
      membershipTitle

      section2Content {
        raw
      }

      links {
        id
        label
        url
      }
      join {
        id
        title1
        title2
        shortText
        content {
          raw
        }
        links {
          id
          label
          url
        }
        image {
          file {
            url
          }
        }
      }

      testimonial {
        id
        author
        designation
        image {
          gatsbyImageData
        }
        testimonial {
          raw
        }
      }

      membershipSmallDisclaimer
    }
  }
`
