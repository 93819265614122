import React, { useState } from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import downloadIcon from "../../images/download-icon.svg"
import Modal from "react-modal"
import "react-datepicker/dist/react-datepicker.css"
import OutdoorForm from "../membership/outdoor-form"
import IndoorForm from "../membership/indoor-place-form"
import AgencyForm from "../membership/agency--form"
import SSPForm from "../membership/ssp-form"
import DSPForm from "../membership/dsp-form"

const Join = ({ data }) => {
  const { join } = data

  const [idx, setIdx] = useState(null)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [formValuess, setFormValues] = useState()
  const [indoorformValuess, setIndoorFormValues] = useState()
  const [agencyformValuess, setAgencyformValuess] = useState()
  const [sspformValuess, setSSpformValuess] = useState()
  const [dspformValuess, setDSPformValuess] = useState()
  const [values, setValues] = useState({
    companyName: "",
    outdoorAddress: "",
    city: "",
    province: "",
    postalCode: "",
    contactName: "",
    phone: "",
    email: "",
    productName: "",
    productFormat: "",
    productDimensions: "",
    productMarket: "",
    numberofBoards: "",
    numberOfFaces: "",
    // filename:"",
    // ---- starting digital values
    digitalProductName: "",
    formatAndDimensions: "",
    simultaneously: "",
    networkingMarkets: "",
    loopRunsFullScheduling: "",
    hoursPerWeek: "",
    advertiserFullScreen: "",
    loopLengthInSeconds: "",
    adSpotsOffered: "",
    digitalProduct: "",
    digitalMarket: "",
    digitalSpot: "",
    maximumNumberOfAdvertisersOver4Weeks: "",
    natureOfDigitalContent: "",
    ifOtherNatureOfDigitalContent: "",
    indoorProductLocation: "",
    ifOtherIndoorProductLocation: "",
    doYouMeasureFootTraffic: "",
    measureFootTrafficResponse: "",
    COMMBDataAuthorization: "",
    whosYourAudience: "",
    formAuthorization: "",
    // formSignDate: "",
  })

  const [indoorValues, setIndoorValues] = useState({
    companyName: "",
    indoorAddress: "",
    city: "",
    province: "",
    postalCode: "",
    contactName: "",
    phone: "",
    email: "",
    productName: "",
    productFormat: "",
    productDimensions: "",
    productMarket: "",
    numberofBoards: "",
    numberOfFaces: "",
    // filename:"",
    // ---- starting digital values
    digitalProductName: "",
    formatAndDimensions: "",
    simultaneously: "",
    networkingMarkets: "",
    loopRunsFullScheduling: "",
    hoursPerWeek: "",
    advertiserFullScreen: "",
    loopLengthInSeconds: "",
    adSpotsOffered: "",
    digitalProduct: "",
    digitalMarket: "",
    digitalSpot: "",
    maximumNumberOfAdvertisersOver4Weeks: "",
    natureOfDigitalContent: "",
    ifOtherNatureOfDigitalContent: "",
    indoorProductLocation: "",
    ifOtherIndoorProductLocation: "",
    doYouMeasureFootTraffic: "",
    measureFootTrafficResponse: "",
    COMMBDataAuthorization: "",
    whosYourAudience: "",
    formAuthorization: "",
    // formSignDate: "",
  })

  const [agencyValues, setAgencyValues] = useState({
    companyName: "",
    billingsFiveMil: "",
    agencyAddress: "",
    city: "",
    province: "",
    postalCode: "",
    phone: "",
    email: "",
    website: "",
    votingRepName: "",
    repJobTitle: "",
    repPhoneNumber: "",
    repEmail: "",
  })

  const [SSPvalues, setSSPvalues] = useState({
    companyName: "",
    billingsFiveMil: "",
    sspAddress: "",
    city: "",
    province: "",
    postalCode: "",
    contactName: "",
    phone: "",
    email: "",
    website: "",
    votingRepName: "",
    repJobTitle: "",
    repPhoneNumber: "",
    repEmail: "",
  })
  const [DSPvalues, setDSPvalues] = useState({
    companyName: "",
    billingsFiveMil: "",
    dspAddress: "",
    city: "",
    province: "",
    postalCode: "",
    phone: "",
    email: "",
    website: "",
    votingRepName: "",
    repJobTitle: "",
    repPhoneNumber: "",
    repEmail: "",
  })
  function openModal(i) {
    setIdx(i)
    setIsOpen(true)

    const formValues = localStorage.getItem("outdoorForm")
    const parseObject = JSON.parse(formValues)
    const indoorFormObject = localStorage.getItem("indoorForm")
    const indoorParseObject = JSON.parse(indoorFormObject)
    const agencyFormObject = localStorage.getItem("agencyForm")
    const agencyParseObject = JSON.parse(agencyFormObject)
    const SSPFormObject = localStorage.getItem("sspForm")
    const SSPParseObject = JSON.parse(SSPFormObject)
    const DSPFormObject = localStorage.getItem("dspForm")
    const DSPParseObject = JSON.parse(DSPFormObject)
    setValues({
      companyName: parseObject && parseObject.companyName,
      outdoorAddress: parseObject && parseObject.outdoorAddress,
      city: parseObject && parseObject.city,
      province: parseObject && parseObject.province,
      postalCode: parseObject && parseObject.postalCode,
      contactName: parseObject && parseObject.contactName,
      phone: parseObject && parseObject.phone,
      email: parseObject && parseObject.email,
      productName: parseObject && parseObject.productName,
      productFormat: parseObject && parseObject.productFormat,
      productDimensions: parseObject && parseObject.productDimensions,
      productMarket: parseObject && parseObject.productMarket,
      numberofBoards: parseObject && parseObject.numberofBoards,
      numberOfFaces: parseObject && parseObject.numberOfFaces,
      digitalProductName: parseObject && parseObject.digitalProductName,
      formatAndDimensions: parseObject && parseObject.formatAndDimensions,
      simultaneously: parseObject && parseObject.simultaneously,
      networkingMarkets: parseObject && parseObject.networkingMarkets,
      loopRunsFullScheduling: parseObject && parseObject.loopRunsFullScheduling,
      hoursPerWeek: parseObject && parseObject.hoursPerWeek,
      advertiserFullScreen: parseObject && parseObject.advertiserFullScreen,
      loopLengthInSeconds: parseObject && parseObject.loopLengthInSeconds,
      adSpotsOffered: parseObject && parseObject.adSpotsOffered,
      digitalProduct: parseObject && parseObject.digitalProduct,
      digitalMarket: parseObject && parseObject.digitalMarket,
      digitalSpot: parseObject && parseObject.digitalSpot,
      maximumNumberOfAdvertisersOver4Weeks:
        parseObject && parseObject.maximumNumberOfAdvertisersOver4Weeks,
      natureOfDigitalContent: parseObject && parseObject.natureOfDigitalContent,
      ifOtherNatureOfDigitalContent:
        parseObject && parseObject.ifOtherNatureOfDigitalContent,
      indoorProductLocation: parseObject && parseObject.indoorProductLocation,
      ifOtherIndoorProductLocation:
        parseObject && parseObject.ifOtherIndoorProductLocation,
      doYouMeasureFootTraffic:
        parseObject && parseObject.doYouMeasureFootTraffic,
      measureFootTrafficResponse:
        parseObject && parseObject.measureFootTrafficResponse,
      COMMBDataAuthorization: parseObject && parseObject.COMMBDataAuthorization,
      whosYourAudience: parseObject && parseObject.whosYourAudience,
      formAuthorization: parseObject && parseObject.formAuthorization,
    })
    setIndoorValues({
      companyName: indoorParseObject && indoorParseObject.companyName,
      indoorAddress: indoorParseObject && indoorParseObject.indoorAddress,
      city: indoorParseObject && indoorParseObject.city,
      province: indoorParseObject && indoorParseObject.province,
      postalCode: indoorParseObject && indoorParseObject.postalCode,
      contactName: indoorParseObject && indoorParseObject.contactName,
      phone: indoorParseObject && indoorParseObject.phone,
      email: indoorParseObject && indoorParseObject.email,
      productName: indoorParseObject && indoorParseObject.productName,
      productFormat: indoorParseObject && indoorParseObject.productFormat,
      productDimensions:
        indoorParseObject && indoorParseObject.productDimensions,
      productMarket: indoorParseObject && indoorParseObject.productMarket,
      numberofBoards: indoorParseObject && indoorParseObject.numberofBoards,
      numberOfFaces: indoorParseObject && indoorParseObject.numberOfFaces,
      digitalProductName:
        indoorParseObject && indoorParseObject.digitalProductName,
      formatAndDimensions:
        indoorParseObject && indoorParseObject.formatAndDimensions,
      simultaneously: indoorParseObject && indoorParseObject.simultaneously,
      networkingMarkets:
        indoorParseObject && indoorParseObject.networkingMarkets,
      loopRunsFullScheduling:
        indoorParseObject && indoorParseObject.loopRunsFullScheduling,
      hoursPerWeek: indoorParseObject && indoorParseObject.hoursPerWeek,
      advertiserFullScreen:
        indoorParseObject && indoorParseObject.advertiserFullScreen,
      loopLengthInSeconds:
        indoorParseObject && indoorParseObject.loopLengthInSeconds,
      adSpotsOffered: indoorParseObject && indoorParseObject.adSpotsOffered,
      digitalProduct: indoorParseObject && indoorParseObject.digitalProduct,
      digitalMarket: indoorParseObject && indoorParseObject.digitalMarket,
      digitalSpot: indoorParseObject && indoorParseObject.digitalSpot,
      maximumNumberOfAdvertisersOver4Weeks:
        indoorParseObject &&
        indoorParseObject.maximumNumberOfAdvertisersOver4Weeks,
      natureOfDigitalContent:
        indoorParseObject && indoorParseObject.natureOfDigitalContent,
      ifOtherNatureOfDigitalContent:
        indoorParseObject && indoorParseObject.ifOtherNatureOfDigitalContent,
      indoorProductLocation:
        indoorParseObject && indoorParseObject.indoorProductLocation,
      ifOtherIndoorProductLocation:
        indoorParseObject && indoorParseObject.ifOtherIndoorProductLocation,
      doYouMeasureFootTraffic:
        indoorParseObject && indoorParseObject.doYouMeasureFootTraffic,
      measureFootTrafficResponse:
        indoorParseObject && indoorParseObject.measureFootTrafficResponse,
      COMMBDataAuthorization:
        indoorParseObject && indoorParseObject.COMMBDataAuthorization,
      whosYourAudience: indoorParseObject && indoorParseObject.whosYourAudience,
      formAuthorization:
        indoorParseObject && indoorParseObject.formAuthorization,
    })
    setAgencyValues({
      companyName: agencyParseObject && agencyParseObject.companyName,
      billingsFiveMil: agencyParseObject && agencyParseObject.billingsFiveMil,
      agencyAddress: agencyParseObject && agencyParseObject.agencyAddress,
      city: agencyParseObject && agencyParseObject.city,
      province: agencyParseObject && agencyParseObject.province,
      postalCode: agencyParseObject && agencyParseObject.postalCode,
      phone: agencyParseObject && agencyParseObject.phone,
      email: agencyParseObject && agencyParseObject.email,
      website: agencyParseObject && agencyParseObject.website,
      votingRepName: agencyParseObject && agencyParseObject.votingRepName,
      repJobTitle: agencyParseObject && agencyParseObject.repJobTitle,
      repPhoneNumber: agencyParseObject && agencyParseObject.repPhoneNumber,
      repEmail: agencyParseObject && agencyParseObject.repEmail,
    })
    setSSPvalues({
      companyName: SSPParseObject && SSPParseObject.companyName,
      billingsFiveMil: SSPParseObject && SSPParseObject.billingsFiveMil,
      sspAddress: SSPParseObject && SSPParseObject.sspAddress,
      city: SSPParseObject && SSPParseObject.city,
      province: SSPParseObject && SSPParseObject.province,
      postalCode: SSPParseObject && SSPParseObject.postalCode,
      contactName: SSPParseObject && SSPParseObject.contactName,
      phone: SSPParseObject && SSPParseObject.phone,
      email: SSPParseObject && SSPParseObject.email,
      website: SSPParseObject && SSPParseObject.website,
      votingRepName: SSPParseObject && SSPParseObject.votingRepName,
      repJobTitle: SSPParseObject && SSPParseObject.repJobTitle,
      repPhoneNumber: SSPParseObject && SSPParseObject.repPhoneNumber,
      repEmail: SSPParseObject && SSPParseObject.repEmail,
    })
    setDSPvalues({
      companyName: DSPParseObject && DSPParseObject.companyName,
      billingsFiveMil: DSPParseObject && DSPParseObject.billingsFiveMil,
      dspAddress: DSPParseObject && DSPParseObject.dspAddress,
      city: DSPParseObject && DSPParseObject.city,
      province: DSPParseObject && DSPParseObject.province,
      postalCode: DSPParseObject && DSPParseObject.postalCode,
      phone: DSPParseObject && DSPParseObject.phone,
      email: DSPParseObject && DSPParseObject.email,
      website: DSPParseObject && DSPParseObject.website,
      votingRepName: DSPParseObject && DSPParseObject.votingRepName,
      repJobTitle: DSPParseObject && DSPParseObject.repJobTitle,
      repPhoneNumber: DSPParseObject && DSPParseObject.repPhoneNumber,
      repEmail: DSPParseObject && DSPParseObject.repEmail,
    })

    setFormValues(indoorParseObject)
    setIndoorFormValues(indoorParseObject)
    setAgencyformValuess(agencyParseObject)
    setSSpformValuess(SSPParseObject)
    setDSPformValuess(DSPParseObject)
  }
  function closeModal() {
    setIsOpen(false)
  }
  // %############################  OutdoorForm Handlers     ########################################%

  const handleChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value })
    localStorage.setItem("outdoorForm", JSON.stringify(values))
  }

  const provinceOnChange = selectedOption => {
    setValues({ ...values, province: selectedOption })

    localStorage.setItem(
      "outdoorForm",
      JSON.stringify({ ...values, province: selectedOption })
    )
  }

  const onChangeTwo = selectedOptionTwo => {
    setValues({ ...values, productFormat: selectedOptionTwo })
    localStorage.setItem(
      "outdoorForm",
      JSON.stringify({ ...values, productFormat: selectedOptionTwo })
    )
  }

  const onChangeThree = selectedOptionThree => {
    setValues({ ...values, natureOfDigitalContent: selectedOptionThree })
    localStorage.setItem(
      "outdoorForm",
      JSON.stringify({ ...values, natureOfDigitalContent: selectedOptionThree })
    )
  }

  const onChangeFour = selectedOptionFour => {
    setValues({ ...values, indoorProductLocation: selectedOptionFour })
    localStorage.setItem(
      "outdoorForm",
      JSON.stringify({ ...values, indoorProductLocation: selectedOptionFour })
    )
  }

  const onChangeFive = selectedOptionFive => {
    setValues({ ...values, doYouMeasureFootTraffic: selectedOptionFive })
    localStorage.setItem(
      "outdoorForm",
      JSON.stringify({ ...values, doYouMeasureFootTraffic: selectedOptionFive })
    )
  }

  const onChangeSix = selectedOptionSix => {
    setValues({ ...values, COMMBDataAuthorization: selectedOptionSix })
    localStorage.setItem(
      "outdoorForm",
      JSON.stringify({ ...values, COMMBDataAuthorization: selectedOptionSix })
    )
  }
  // %############################  OutdoorForm Handlers END    ########################################%
  // %############################  IndoorForm Handlers    ########################################%
  const handleChangeInDoor = e => {
    setIndoorValues({ ...indoorValues, [e.target.name]: e.target.value })
    localStorage.setItem("indoorForm", JSON.stringify(indoorValues))
  }

  const provinceOnChangeInDoor = selectedOption => {
    setIndoorValues({ ...indoorValues, province: selectedOption })

    localStorage.setItem(
      "indoorForm",
      JSON.stringify({ ...indoorValues, province: selectedOption })
    )
  }

  const onChangeTwoInDoor = selectedOptionTwo => {
    setIndoorValues({ ...indoorValues, productFormat: selectedOptionTwo })
    localStorage.setItem(
      "indoorForm",
      JSON.stringify({ ...indoorValues, productFormat: selectedOptionTwo })
    )
  }

  const onChangeThreeInDoor = selectedOptionThree => {
    setIndoorValues({
      ...indoorValues,
      natureOfDigitalContent: selectedOptionThree,
    })
    localStorage.setItem(
      "indoorForm",
      JSON.stringify({
        ...indoorValues,
        natureOfDigitalContent: selectedOptionThree,
      })
    )
  }

  const onChangeFourInDoor = selectedOptionFour => {
    setIndoorValues({
      ...indoorValues,
      indoorProductLocation: selectedOptionFour,
    })
    localStorage.setItem(
      "indoorForm",
      JSON.stringify({
        ...indoorValues,
        indoorProductLocation: selectedOptionFour,
      })
    )
  }

  const onChangeFiveInDoor = selectedOptionFive => {
    setIndoorValues({
      ...indoorValues,
      doYouMeasureFootTraffic: selectedOptionFive,
    })
    localStorage.setItem(
      "indoorForm",
      JSON.stringify({
        ...indoorValues,
        doYouMeasureFootTraffic: selectedOptionFive,
      })
    )
  }

  const onChangeSixInDoor = selectedOptionSix => {
    setIndoorValues({
      ...indoorValues,
      COMMBDataAuthorization: selectedOptionSix,
    })
    localStorage.setItem(
      "indoorForm",
      JSON.stringify({
        ...indoorValues,
        COMMBDataAuthorization: selectedOptionSix,
      })
    )
  }
  // %############################  IndoorForm Handler End  ########################################%

  // %############################  Agency Form Handlers     ########################################%
  const handleChangeAgency = e => {
    localStorage.setItem("agencyForm", JSON.stringify(agencyValues))
    setAgencyValues({ ...agencyValues, [e.target.name]: e.target.value })
  }

  const provinceOnChangeAgency = selectedOption => {
    setAgencyValues({ ...agencyValues, province: selectedOption })
    localStorage.setItem(
      "agencyForm",
      JSON.stringify({ ...agencyValues, province: selectedOption })
    )
  }

  const onChangeTwoAgency = selectedOptionTwo => {
    setAgencyValues({ ...agencyValues, billingsFiveMil: selectedOptionTwo })
    localStorage.setItem(
      "agencyForm",
      JSON.stringify({ ...agencyValues, billingsFiveMil: selectedOptionTwo })
    )
  }
  // %############################  Agency Form Handlers END    ########################################%
  // %############################  SSP Form Handlers     ########################################%

  const handleChangeSSP = e => {
    localStorage.setItem("sspForm", JSON.stringify(SSPvalues))
    setSSPvalues({ ...SSPvalues, [e.target.name]: e.target.value })
  }

  const provinceOnChangeSSP = selectedOption => {
    setSSPvalues({ ...SSPvalues, province: selectedOption })
    localStorage.setItem(
      "sspForm",
      JSON.stringify({ ...SSPvalues, province: selectedOption })
    )
  }

  const onChangeTwoSSP = selectedOptionTwo => {
    setSSPvalues({ ...SSPvalues, billingsFiveMil: selectedOptionTwo })
    localStorage.setItem(
      "sspForm",
      JSON.stringify({ ...SSPvalues, billingsFiveMil: selectedOptionTwo })
    )
  }
  // %############################  SSP Form Handlers  END   ########################################%
  // %############################  DSP Form Handlers     ########################################%
  const handleChangeDSP = e => {
    localStorage.setItem("dspForm", JSON.stringify(DSPvalues))
    setDSPvalues({ ...DSPvalues, [e.target.name]: e.target.value })
  }

  const provinceOnChangeDSP = selectedOption => {
    setDSPvalues({ ...DSPvalues, province: selectedOption })
    localStorage.setItem(
      "dspForm",
      JSON.stringify({ ...DSPvalues, province: selectedOption })
    )
  }

  const onChangeTwoDSP = selectedOptionTwo => {
    setDSPvalues({ ...DSPvalues, billingsFiveMil: selectedOptionTwo })
    localStorage.setItem(
      "dspForm",
      JSON.stringify({ ...DSPvalues, billingsFiveMil: selectedOptionTwo })
    )
  }
  Modal.setAppElement("#___gatsby")
  const [startDate, setStartDate] = useState(new Date())
  const renderCard = (item, i) => {
    if (i % 2 === 0) {
      return (
        <div className="joincard_block">
          <a className="fauxAnchor" id="ooh"></a>
          <div className="join_card_info_block join_order">
            <div className="jion_inner_info">
              <div className="joincard_title">
                <div className="commn_title_label">
                  <p>{item.shortText}</p>
                </div>
                <div className="common_inner_title">
                  {item.title2 ? (
                    <h3>
                      {item.title1} <br /> {item.title2}
                    </h3>
                  ) : (
                    <h3>{item.title1}</h3>
                  )}
                </div>
              </div>
              <div className="common_desc">
                <p>{renderRichText(item.content)}</p>
              </div>
              {item.links.map((link, i) => {
                if (link.url.includes("@")) {
                  return (
                    <p className="email_link" key={link.id}>
                      {link.label}
                      <a href={`mailto:${link.url}`}> {link.url}.</a>
                    </p>
                  )
                } else {
                  return (
                    <p className="link_btns" key={link.id}>
                      <a href={link.url} onClick={() => openModal(link.id)}>
                        {link.label}
                        <span>
                          <img src={downloadIcon} alt="download_icon" />
                        </span>
                      </a>
                      {modalIsOpen &&
                        link.id === "df417fee-58c0-55e9-90b4-b31c5e1d3bfa" && (
                          <Modal
                            isOpen={idx === link.id}
                            onRequestClose={closeModal}
                            contentLabel={i.toString()}
                            id={i.toString()}
                            key={i}
                            portalClassName={`outdoorOperatorModal`}
                            testId={i.toString()}
                            bodyOpenClassName={`BookCOMMBModal modalOpen outdoorOperatorModal`}
                          >
                            <OutdoorForm
                              values={values}
                              formValuess={formValuess}
                              handleChange={handleChange}
                              provinceOnChange={provinceOnChange}
                              onChangeTwo={onChangeTwo}
                              onChangeThree={onChangeThree}
                              onChangeFour={onChangeFour}
                              onChangeFive={onChangeFive}
                              onChangeSix={onChangeSix}
                            />
                            <a className="closeBtnModal" onClick={closeModal}>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17.688"
                                height="17.688"
                                viewBox="0 0 17.688 17.688"
                              >
                                <path
                                  id="close-icon"
                                  d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                  transform="translate(-7.5 -7.5)"
                                  fill="#fff"
                                ></path>
                              </svg>
                            </a>
                          </Modal>
                        )}
                      {modalIsOpen &&
                        link.id === "ac7862a9-02fa-53ae-ab7f-54163161f080" && (
                          <Modal
                            isOpen={idx === link.id}
                            onRequestClose={closeModal}
                            contentLabel={i.toString()}
                            id={i.toString()}
                            key={i}
                            portalClassName={`outdoorOperatorModal`}
                            testId={i.toString()}
                            bodyOpenClassName={`BookCOMMBModal modalOpen outdoorOperatorModal`}
                          >
                            <IndoorForm
                              values={indoorValues}
                              indoorformValuess={
                                indoorformValuess !== null && indoorformValuess
                              }
                              handleChangeInDoor={handleChangeInDoor}
                              provinceOnChangeInDoor={provinceOnChangeInDoor}
                              onChangeTwoInDoor={onChangeTwoInDoor}
                              onChangeThreeInDoor={onChangeThreeInDoor}
                              onChangeFourInDoor={onChangeFourInDoor}
                              onChangeFiveInDoor={onChangeFiveInDoor}
                              onChangeSixInDoor={onChangeSixInDoor}
                            />
                            <a className="closeBtnModal" onClick={closeModal}>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17.688"
                                height="17.688"
                                viewBox="0 0 17.688 17.688"
                              >
                                <path
                                  id="close-icon"
                                  d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                  transform="translate(-7.5 -7.5)"
                                  fill="#fff"
                                ></path>
                              </svg>
                            </a>
                          </Modal>
                        )}

                      {modalIsOpen &&
                        link.id === "b9e1d522-566b-5150-8ceb-e778677d1599" && (
                          <Modal
                            isOpen={idx === link.id}
                            onRequestClose={closeModal}
                            contentLabel={i.toString()}
                            id={i.toString()}
                            key={i}
                            portalClassName={`outdoorOperatorModal`}
                            testId={i.toString()}
                            bodyOpenClassName={`BookCOMMBModal modalOpen outdoorOperatorModal`}
                          >
                            <div className="common_section_title">
                              <h2>
                                Outdoor Operator <span> Application Form</span>
                              </h2>
                            </div>
                            <p>Moving Media Operator {link.id}</p>
                            <a className="closeBtnModal" onClick={closeModal}>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17.688"
                                height="17.688"
                                viewBox="0 0 17.688 17.688"
                              >
                                <path
                                  id="close-icon"
                                  d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                  transform="translate(-7.5 -7.5)"
                                  fill="#fff"
                                ></path>
                              </svg>
                            </a>
                          </Modal>
                        )}

                      {modalIsOpen &&
                        link.id === "232c2b43-956a-52dd-881d-87e0647830c9" && (
                          <Modal
                            isOpen={idx === link.id}
                            onRequestClose={closeModal}
                            contentLabel={i.toString()}
                            id={i.toString()}
                            key={i}
                            portalClassName={`outdoorOperatorModal`}
                            testId={i.toString()}
                            bodyOpenClassName={`BookCOMMBModal modalOpen outdoorOperatorModal`}
                          >
                            <div className="common_section_title">
                              <SSPForm
                                values={SSPvalues}
                                sspformValuess={
                                  sspformValuess !== null && sspformValuess
                                }
                                handleChangeSSP={handleChangeSSP}
                                provinceOnChangeSSP={provinceOnChangeSSP}
                                onChangeTwoSSP={onChangeTwoSSP}
                              />
                              <a className="closeBtnModal" onClick={closeModal}>
                                {" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17.688"
                                  height="17.688"
                                  viewBox="0 0 17.688 17.688"
                                >
                                  <path
                                    id="close-icon"
                                    d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                    transform="translate(-7.5 -7.5)"
                                    fill="#fff"
                                  ></path>
                                </svg>
                              </a>
                            </div>
                          </Modal>
                        )}
                      {modalIsOpen &&
                        link.id === "eb79a3ac-fe7d-5108-aa77-a0ea20bc8936" && (
                          <Modal
                            isOpen={idx === link.id}
                            onRequestClose={closeModal}
                            contentLabel={i.toString()}
                            id={i.toString()}
                            key={i}
                            portalClassName={`outdoorOperatorModal`}
                            testId={i.toString()}
                            bodyOpenClassName={`BookCOMMBModal modalOpen outdoorOperatorModal`}
                          >
                            <DSPForm
                              values={DSPvalues}
                              dspformValuess={
                                dspformValuess !== null && dspformValuess
                              }
                              handleChangeDSP={handleChangeDSP}
                              provinceOnChangeDSP={provinceOnChangeDSP}
                              onChangeTwoDSP={onChangeTwoDSP}
                            />
                            <a className="closeBtnModal" onClick={closeModal}>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17.688"
                                height="17.688"
                                viewBox="0 0 17.688 17.688"
                              >
                                <path
                                  id="close-icon"
                                  d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                  transform="translate(-7.5 -7.5)"
                                  fill="#fff"
                                ></path>
                              </svg>
                            </a>
                          </Modal>
                        )}
                    </p>
                  )
                }
              })}
            </div>
          </div>
          <div className="joincard_image_block new-bg">
            <a href="#0">
              <img src={item.image.file?.url} alt={item.title1} />
            </a>
          </div>
        </div>
      )
    } else {
      return (
        <div className="joincard_block">
          <a className="fauxAnchor" id="agency-advertiser"></a>
          <div className="joincard_image_block new-bg">
            <a href="#0">
              <img src={item.image.file?.url} alt={item.title1} />
            </a>
          </div>
          <div className="join_card_info_block">
            <div className="jion_inner_info">
              <div className="joincard_title">
                <div className="commn_title_label">
                  <p>
                    {item.shortText}
                    {/* agencies <span className="heading_bar"> | </span> advertisers */}
                  </p>
                </div>
                <div className="common_inner_title">
                  {item.title2 ? (
                    <h3>
                      {item.title1} <br /> {item.title2}
                    </h3>
                  ) : (
                    <h3>{item.title1}</h3>
                  )}
                </div>
              </div>
              <div className="common_desc">
                <p>{renderRichText(item.content)}</p>
              </div>
              {item.links.map(link => {
                if (link.url.includes("@")) {
                  return (
                    <p className="email_link" key={link.id}>
                      {link.label}
                      <a href={`mailto:${link.url}`}> {link.url}.</a>
                    </p>
                  )
                } else {
                  return (
                    <p className="link_btns" key={link.id}>
                      <a href={link.url} onClick={() => openModal(link.id)}>
                        {link.label}
                        <span>
                          <img src={downloadIcon} alt="download_icon" />
                        </span>
                      </a>
                      {modalIsOpen && (
                        <Modal
                          isOpen={idx === link.id}
                          onRequestClose={closeModal}
                          contentLabel={i.toString()}
                          portalClassName={`outdoorOperatorModal`}
                          id={i.toString()}
                          key={i}
                          testId={i.toString()}
                          bodyOpenClassName={`BookCOMMBModal modalOpen outdoorOperatorModal`}
                        >
                          <AgencyForm
                            values={agencyValues}
                            agencyformValuess={
                              agencyformValuess !== null && agencyformValuess
                            }
                            handleChangeAgency={handleChangeAgency}
                            provinceOnChangeAgency={provinceOnChangeAgency}
                            onChangeTwoAgency={onChangeTwoAgency}
                          />
                          <a className="closeBtnModal" onClick={closeModal}>
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17.688"
                              height="17.688"
                              viewBox="0 0 17.688 17.688"
                            >
                              <path
                                id="close-icon"
                                d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                transform="translate(-7.5 -7.5)"
                                fill="#fff"
                              ></path>
                            </svg>
                          </a>
                        </Modal>
                      )}
                    </p>
                  )
                }
              })}
            </div>
          </div>
        </div>
      )
    }
  }
  return (
    <div className="joincard_section">
      <div className="container">
        {join.map((item, i) => renderCard(item, i))}
      </div>
    </div>
  )
}

export default Join
