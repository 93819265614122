import React, { useState } from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import downloadIcon from "../../images/download-icon.svg"
import Modal from "react-modal"
import Select from "react-select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const customStyles = {
  control: (base, state) => ({
    ...base,
    background: "transparent",
    border: "1px solid #fff",
    color: "red",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#bf1116" : "#bf1116",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#bf1116" : "#bf1116",
    },
  }),
  menu: base => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    border: "1px solid #fff",
    // kill the gap
    marginTop: 0,
  }),
  menuList: base => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    background: "#151515",
    color: "#bf1116",
  }),
}

const options = [
  { value: "Alberta", label: "Alberta" },
  { value: "British Columbia", label: "British Columbia" },
  { value: "New Brunswick", label: "New Brunswick" },
  { value: "Newfoundland and Labrador", label: "Newfoundland and Labrador" },
  { value: "Nova Scotia", label: "Nova Scotia" },
  { value: "Ontario", label: "Ontario" },
  { value: "Prince Edward Island", label: "Prince Edward Island" },
  { value: "Quebec", label: "Quebec" },
  { value: "Saskatchewan", label: "Saskatchewan" },
  { value: "Northwest Territories", label: "Northwest Territories" },
  { value: "Nunavut", label: "Nunavut" },
  { value: "Yukon", label: "Yukon" },
]

const formatOptions = [
  { value: "Static", label: "Static" },
  { value: "Backlit", label: "Backlit" },
]

const natureOfDigitalContentOptions = [
  { value: "Advertising", label: "Advertising" },
  { value: "News", label: "News" },
  { value: "Entertainment", label: "Entertainment" },
  { value: "Education", label: "Education" },
  { value: "Other", label: "Other" },
]

const indoorProductLocationOptions = [
  { value: "Street Level", label: "Street Level" },
  { value: "Building", label: "Building" },
  { value: "Highway", label: "Highway" },
  { value: "Airport", label: "Airport" },
  { value: "Other", label: "Other" },
]

const booleanOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
]

const IndoorForm = () => {
  const [values, setValues] = useState({
    companyName: "",
    indoorAddress: "",
    city: "",
    province: "",
    postalCode: "",
    contactName: "",
    phone: "",
    email: "",
    productName: "",
    productFormat: "",
    productDimensions: "",
    productMarket: "",
    numberofBoards: "",
    numberOfFaces: "",
    // filename:"",
    // ---- starting digital values
    digitalProductName: "",
    formatAndDimensions: "",
    simultaneously: "",
    networkingMarkets: "",
    loopRunsFullScheduling: "",
    hoursPerWeek: "",
    advertiserFullScreen: "",
    loopLengthInSeconds: "",
    adSpotsOffered: "",
    digitalProduct: "",
    digitalMarket: "",
    maximumNumberOfAdvertisersOver4Weeks: "",
    natureOfDigitalContent: "",
    ifOtherNatureOfDigitalContent: "",
    indoorProductLocation: "",
    ifOtherIndoorProductLocation: "",
    doYouMeasureFootTraffic: "",
    measureFootTrafficResponse: "",
    COMMBDataAuthorization: "",
    whosYourAudience: "",
    formAuthorization: "",
    // formSignDate: "",
  })
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const {
    companyName,
    indoorAddress,
    city,
    province,
    postalCode,
    contactName,
    phone,
    email,
    productName,
    productFormat,
    productDimensions,
    productMarket,
    numberofBoards,
    numberOfFaces,
    digitalProductName,
    formatAndDimensions,
    simultaneously,
    networkingMarkets,
    loopRunsFullScheduling,
    hoursPerWeek,
    advertiserFullScreen,
    loopLengthInSeconds,
    adSpotsOffered,
    digitalProduct,
    digitalMarket,
    maximumNumberOfAdvertisersOver4Weeks,
    natureOfDigitalContent,
    ifOtherNatureOfDigitalContent,
    indoorProductLocation,
    ifOtherIndoorProductLocation,
    doYouMeasureFootTraffic,
    measureFootTrafficResponse,
    COMMBDataAuthorization,
    whosYourAudience,
    formAuthorization,
  } = values

  const handleChange = e =>
    setValues({ ...values, [e.target.name]: e.target.value })

  const onChange = selectedOption => {
    setValues({ ...values, province: selectedOption })
    // console.log(`Option selected:`, selectedOption);
  }

  const onChangeTwo = selectedOptionTwo => {
    setValues({ ...values, productFormat: selectedOptionTwo })
    // console.log(`Option selected:`, selectedOption);
  }

  const onChangeThree = selectedOptionThree => {
    setValues({ ...values, natureOfDigitalContent: selectedOptionThree })
    // console.log(`Option selected:`, selectedOption);
  }

  const onChangeFour = selectedOptionFour => {
    setValues({ ...values, indoorProductLocation: selectedOptionFour })
    // console.log(`Option selected:`, selectedOption);
  }

  const onChangeFive = selectedOptionFive => {
    setValues({ ...values, doYouMeasureFootTraffic: selectedOptionFive })
    // console.log(`Option selected:`, selectedOption);
  }

  const onChangeSix = selectedOptionSix => {
    setValues({ ...values, COMMBDataAuthorization: selectedOptionSix })
    // console.log(`Option selected:`, selectedOption);
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  const handleSubmit = async e => {
    console.log(values, "values")
    console.log("Clicked!")
    e.preventDefault()
    setError("")
    setSuccess(false)

    if (
      !companyName ||
      !indoorAddress ||
      !city ||
      !province.value ||
      !postalCode ||
      !contactName ||
      !productName ||
      !productFormat ||
      !digitalProductName ||
      !formatAndDimensions ||
      !digitalProduct ||
      !natureOfDigitalContent.value
    ) {
      setError("This is a required field.")
      return
    }

    // if (!validateEmail(email)) {
    //   setError("Please provide a valid email")
    //   return
    // }

    try {
      const res = await fetch("/.netlify/functions/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
      if (res.ok !== false) {
        console.log(res.ok, "res")
        console.log(res, "res")
        setSuccess(true)
        document
          .querySelector("form.indoor")
          .classList.add("formSubmitted");
        document
          .querySelector("form.indoor button").disabled = true;
      } else {
        console.log("")
      }
    } catch (err) {
      console.log(err)
      setError("Something went wrong")
    }
  }
    
    return (
        <div>
            <div className="common_section_title membership-top-form">
              <h2>
                Place-Based Operator{" "}
                <span>Application Form</span>
              </h2>
              <ol>
                <li>
                  Submit application form with the Initiation Fee
                </li>
                <li>
                  Determine appropriate research methodology: a.
                  If your product cannot be measured using an
                  existing COMMB methodology, COMMB will work
                  with you under the direction of the COMMB
                  Research Committee, to develop a methodology
                  specific to your advertising format. You will
                  be responsible for costs required to achieve
                  this.
                </li>
                <li>
                  During the membership process, applicants may
                  be asked to present an overview of their
                  company to COMMB’s ResearchCommittee.
                </li>
                <li>
                  Once methodology is established, there will be
                  a vote to accept new membership at a session
                  of COMMB’s Research Committee, followed by a
                  vote of approval by COMMB’s Board of Directors.
                </li>
              </ol>
              <h4>COMMB Fees/Dues</h4>
              <p>
                COMMB membership fees consist of three
                components.
              </p>
              <ol>
                <li>
                  <h4> Initial Membership Fee</h4>
                  <div className="membership-table">
                    <div className="table-row">
                      <div>
                        <p>
                          First Year Annual <br /> Membership
                          Dues
                        </p>
                      </div>
                      <div>
                        <p>
                          Initiation Fees Operator Utilizes
                          Existing Methodology
                          <small>(plus applicable taxes)</small>
                        </p>
                      </div>
                      <div>
                        <p>
                          Initiation Fees Operator Requires
                          Development of New Methodology *
                          <small>(plus applicable taxes)</small>
                        </p>
                      </div>
                    </div>
                    <div className="table-row two">
                      <div>
                        <p>&lt; $5,000</p>
                      </div>
                      <div>
                        <p>$2,500</p>
                      </div>
                      <div>
                        <p>$7,500</p>
                      </div>
                    </div>
                    <div className="table-row three">
                      <div>
                        <p>$5,001 - $49,999</p>
                      </div>
                      <div>
                        <p>$5,000</p>
                      </div>
                      <div>
                        <p>$10,000</p>
                      </div>
                    </div>
                    <div className="table-row four">
                      <div>
                        <p>$50,000 +</p>
                      </div>
                      <div>
                        <p>+ 10,000</p>
                      </div>
                      <div>
                        <p>$15,000</p>
                      </div>
                    </div>
                    <small className="note-table">
                      Note: The initial membership term is for
                      two years and then annually thereafter
                    </small>
                  </div>
                  <p>
                    If your product requires a new methodology
                    and you decide not to move forward with
                    membership after reviewing the methodology
                    and costs, $5,000 of the total initiation
                    fee paid would be nonrefundable and retained
                    by COMMB to cover the preliminary
                    assessment.
                  </p>
                  If you agree to the methodology and costs and
                  your membership is approved, none of the
                  initiation fee would be refundable.
                </li>
                <li>
                  <h4>Annual Membership Fees</h4>
                  <p>
                    Annual Membership Fees are based on net
                    annual advertising revenue for OOH formats
                    measured by COMMB. Dues are payable
                    quarterly.
                  </p>
                </li>
                <li>
                  <h4>Research Fees</h4>
                  <p>
                    The cost to conduct circulation updates
                    based on COMMB approved schedules is charged
                    annually.
                  </p>
                </li>
              </ol>
            </div>
            <a href="https://assets.ctfassets.net/1l6si2vnlb2k/2xDn5VbShVAOLQEG9huS9s/47e3b49cb77dcb90d1f7874847f43c91/Place-Based-Operator-Application-form.pdf">
              <small className="note-table">
                You can download the PDF version of the
                application form here.
              </small>
            </a>
            <h4 class="centreMe">
                Company Information - Place Based
            </h4>
            <form onSubmit={handleSubmit} className="indoor">
                <input
                type="text"
                placeholder="Company Name *"
                className="textInput"
                name="companyName"
                value={companyName}
                onChange={handleChange}
                />
                <span className="error_message">
                {!companyName && error ? error : ""}
                </span>
                <input
                type="text"
                placeholder="Address *"
                className="textInput"
                name="indoorAddress"
                value={indoorAddress}
                onChange={handleChange}
                />
                <span className="error_message">{!indoorAddress && error ? error  :""}</span>
                <input
                type="text"
                placeholder="City *"
                className="textInput"
                name="city"
                value={city}
                onChange={handleChange}
                />
                <span className="error_message">{!city && error ?  error  :""}</span>
                <Select
                options={options}
                className={`customSelect custom-option`}
                styles={customStyles}
                classNamePrefix={`contactDropdown`}
                placeholder={`Province: *`}
                name="province"
                value={province}
                onChange={onChange}
                />
                <span className="error_message">{!province && error ? error : ""}</span>
                <input
                type="text"
                className="textInput"
                name="postalCode"
                value={postalCode}
                onChange={handleChange}
                placeholder="Postal Code: *"
                />
                <span className="error_message">{!postalCode &&  error ? error : ""}</span>
                <input
                type="text"
                placeholder="Contact Name/Title:*"
                className="textInput"
                name="contactName"
                value={contactName}
                onChange={handleChange}
                />
                <span className="error_message">{!contactName && error ? error : ""}</span>
                <input
                type="number"
                placeholder="Phone:"
                className="textInput"
                name="phone"
                value={phone}
                onChange={handleChange}
                />
                <input
                type="text"
                placeholder="Email:"
                className="textInput"
                name="email"
                value={email}
                onChange={handleChange}
                />
                <h4>
                Product Information –{" "}
                <small>
                    {" "}
                    (For digital products see next section)
                </small>
                </h4>
                <input
                type="text"
                placeholder="Product Name: *"
                className="textInput"
                name="productName"
                value={productName}
                onChange={handleChange}
                />
                <span className="error_message">{!productName && error ? error : ""}</span>
                <Select
                options={formatOptions}
                className={`customSelect custom-option`}
                styles={customStyles}
                classNamePrefix={`contactDropdown`}
                placeholder={`Product Format Type: *`}
                name="productFormat"
                value={productFormat}
                onChange={onChangeTwo}
                />
                <span className="error_message">{!productFormat && error ? error : ""}</span>
                <input
                type="text"
                placeholder="Dimensions of each product type:"
                name="productDimensions"
                value={productDimensions}
                onChange={handleChange}
                className="textInput"
                />
                <input
                type="text"
                placeholder="Market:"
                className="textInput"
                name="productMarket"
                value={productMarket}
                onChange={handleChange}
                />
                <input
                type="text"
                placeholder="Number of boards (structures) in each market."
                className="textInput"
                name="numberofBoards"
                value={numberofBoards}
                onChange={handleChange}
                />
                <input
                type="text"
                placeholder="Number of faces."
                className="textInput"
                name="numberOfFaces"
                value={numberOfFaces}
                onChange={handleChange}
                />
                {/* <div className="radioFlex">
                If there are multiple markets and products
                please attach your inventory list.
                </div>
                <input type="file" id="myFile" name="filename" value={digitalProductName} onChange={handleChange}    /> */}
                <br />
                <h4>Digital Inventory</h4>
                <input
                type="text"
                placeholder="Product Name: *"
                name="digitalProductName"
                value={digitalProductName}
                onChange={handleChange}
                className="textInput"
                />
                <span className="error_message">{!digitalProductName && error ? error : ""}</span>
                <input
                type="text"
                placeholder="Format and Dimensions: *"
                name="formatAndDimensions"
                value={formatAndDimensions}
                onChange={handleChange}
                className="textInput"
                />
                <span className="error_message">{!formatAndDimensions && error ? error : ""}</span>
                <input
                type="text"
                placeholder="Please confirm that the advertising runs simultaneously on all screens."
                name="simultaneously"
                value={simultaneously}
                onChange={handleChange}
                className="textInput"
                />
                <input
                type="text"
                placeholder="Are the screens networked within a market, or group of markets? Please describe:"
                name="networkingMarkets"
                value={networkingMarkets}
                onChange={handleChange}
                className="textInput"
                />
                <textarea
                rows="1"
                name="loopRunsFullScheduling"
                value={loopRunsFullScheduling}
                onChange={handleChange}
                placeholder="Does each advertiser run in every loop for the full four weeks? If not, please explain how the advertising is scheduled."
                ></textarea>
                <input
                type="text"
                placeholder="How many hours per week are the screens broadcasting?"
                name="hoursPerWeek"
                value={hoursPerWeek}
                onChange={handleChange}
                className="textInput"
                />
                <textarea
                rows="1"
                name="advertiserFullScreen"
                value={advertiserFullScreen}
                onChange={handleChange}
                placeholder="Is the advertiser full screen or otherwise? If the latter, please describe the dimensions of the ad spots and their location on the screen (please include photos for clarification)."
                ></textarea>
                <input
                type="text"
                placeholder="What is the loop length in seconds and the maximum number of minutes allowed for advertising:"
                name="loopLengthInSeconds"
                value={loopLengthInSeconds}
                onChange={handleChange}
                className="textInput"
                />
                <textarea
                rows="1"
                name="adSpotsOffered"
                value={adSpotsOffered}
                onChange={handleChange}
                placeholder="What ad spots do you offer (15, 30, 60 sec or other)."
                ></textarea>
                <input
                type="text"
                placeholder="Product *"
                name="digitalProduct"
                value={digitalProduct}
                onChange={handleChange}
                className="textInput"
                />
               <span className="error_message">{!digitalProduct && error ? error : ""}</span>
                <input
                type="text"
                placeholder="Market"
                name="digitalMarket"
                value={digitalMarket}
                onChange={handleChange}
                className="textInput"
                />
                <input
                type="text"
                placeholder="Maximum number of advertisers over 4 weeks"
                name="maximumNumberOfAdvertisersOver4Weeks"
                value={maximumNumberOfAdvertisersOver4Weeks}
                onChange={handleChange}
                className="textInput"
                />
                <Select
                options={natureOfDigitalContentOptions}
                className={`customSelect custom-option natureVideoDropdown`}
                styles={customStyles}
                classNamePrefix={`natureVideoDropdown`}
                placeholder={`Nature of Digital/Video Content: *`}
                name="natureOfDigitalContent"
                value={natureOfDigitalContent}
                onChange={onChangeThree}
                />
                <span className="error_message">{!natureOfDigitalContent && error ? error : ""}</span>
                <input
                type="text"
                placeholder="If other, please specify:"
                name="ifOtherNatureOfDigitalContent"
                value={ifOtherNatureOfDigitalContent}
                onChange={handleChange}
                className="textInput"
                />
                <Select
                options={indoorProductLocationOptions}
                className={`customSelect custom-option productLocatedDropdown`}
                styles={customStyles}
                classNamePrefix={`productLocatedDropdown`}
                placeholder={`Where is your product located:`}
                name="indoorProductLocation"
                value={indoorProductLocation}
                onChange={onChangeFour}
                />
                {/* <h4>Indoor Environment:</h4>
                <div className="radioFlex smallFont">
                Where is your product located:
                <div className="radioOptions">
                    <input
                    type="radio"
                    value="Street Level"
                    name="indoorProductLocation"
                    />{" "}
                    <span className="yes">Street Level</span>
                    <input
                    type="radio"
                    value="Building"
                    name="indoorProductLocation"
                    />{" "}
                    <span className="no">Building</span>
                    <input
                    type="radio"
                    value="Highway"
                    name="indoorProductLocation"
                    />{" "}
                    <span className="no">Highway</span>
                    <input
                    type="radio"
                    value="Airport"
                    name="indoorProductLocation"
                    />{" "}
                    <span className="no">Airport</span>
                    <input
                    type="radio"
                    value="Other"
                    name="indoorProductLocation"
                    />{" "}
                    <span className="no">Other</span>
                </div>
                </div> */}
                <input
                type="text"
                placeholder="If other, please specify:"
                name="ifOtherIndoorProductLocation"
                value={ifOtherIndoorProductLocation}
                onChange={handleChange}
                className="textInput"
                />
                <h4>Circulation/Audience</h4>
                <Select
                options={booleanOptions}
                className={`customSelect custom-option`}
                styles={customStyles}
                classNamePrefix={`contactDropdown`}
                placeholder={`Do you currently measure pedestrian traffic?:`}
                name="doYouMeasureFootTraffic"
                value={doYouMeasureFootTraffic}
                onChange={onChangeFive}
                />
                <textarea
                rows="2"
                name="measureFootTrafficResponse"
                value={measureFootTrafficResponse}
                onChange={handleChange}
                placeholder="If yes, please provide: a. Your process, technology and/or supplier. b. How often is this measurement conducted? c. In how many markets is this measurement conducted?"
                ></textarea>
                <Select
                options={booleanOptions}
                className={`customSelect custom-option`}
                styles={customStyles}
                classNamePrefix={`contactDropdown`}
                placeholder={`Is the data available for use by COMMB?`}
                name="COMMBDataAuthorization"
                value={COMMBDataAuthorization}
                onChange={onChangeSix}
                />
                <textarea
                rows="2"
                name="whosYourAudience"
                value={whosYourAudience}
                onChange={handleChange}
                placeholder="Who is your audience? Consumers, staff, others please specify."
                ></textarea>
                <p>
                I verify the information provided on this form
                is accurate.
                </p>
                <input
                type="text"
                placeholder="Authorized Name of Applicant & Company"
                name="formAuthorization"
                value={formAuthorization}
                onChange={handleChange}
                className="textInput"
                />
                {/* <p>Designed Start Date:</p>
                <DatePicker
                name="formSignDate"
                value={formSignDate}
                onChange={handleChange}    
                selected={startDate}
                onChange={date => setStartDate(date)}
                /> */}
                {/* <button className="submit">Submit  {errorContact && <p>Checkmark</p>}</button> */}
                <button className="submit"><span className="submitText">Submit</span>
                {success && ( <span className="thankYouMsg">
                    Thank you for contacting us! We'll be in touch
                    with you soon.</span> )}
                </button>
            </form>
        </div>
    )
}
    
    
export default IndoorForm