import React, { useState } from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import downloadIcon from "../../images/download-icon.svg"
import Modal from "react-modal"
import Select from "react-select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const customStyles = {
  control: (base, state) => ({
    ...base,
    background: "transparent",
    border: "1px solid #fff",
    color: "red",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#bf1116" : "#bf1116",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#bf1116" : "#bf1116",
    },
  }),
  menu: base => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    border: "1px solid #fff",
    // kill the gap
    marginTop: 0,
  }),
  menuList: base => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    background: "#151515",
    color: "#bf1116",
  }),
}

const options = [
  { value: "Alberta", label: "Alberta" },
  { value: "British Columbia", label: "British Columbia" },
  { value: "New Brunswick", label: "New Brunswick" },
  { value: "Newfoundland and Labrador", label: "Newfoundland and Labrador" },
  { value: "Nova Scotia", label: "Nova Scotia" },
  { value: "Ontario", label: "Ontario" },
  { value: "Prince Edward Island", label: "Prince Edward Island" },
  { value: "Quebec", label: "Quebec" },
  { value: "Saskatchewan", label: "Saskatchewan" },
  { value: "Northwest Territories", label: "Northwest Territories" },
  { value: "Nunavut", label: "Nunavut" },
  { value: "Yukon", label: "Yukon" },
]

const formatOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
]

const AgencyForm = ({
  values,
  agencyformValuess,
  handleChangeAgency,
  provinceOnChangeAgency,
  onChangeTwoAgency,
}) => {
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const {
    companyName,
    billingsFiveMil,
    agencyAddress,
    city,
    province,
    postalCode,
    phone,
    email,
    website,
    votingRepName,
    repJobTitle,
    repPhoneNumber,
    repEmail,
  } = values

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  const handleSubmit = async e => {
    console.log(values, "values")
    console.log("Clicked!")
    e.preventDefault()
    setError("")
    setSuccess(false)

    if (
      !companyName ||
      !billingsFiveMil.value ||
      !agencyAddress ||
      !city ||
      !province.value ||
      !postalCode ||
      !phone ||
      !email ||
      !website
    ) {
      setError("This is a required field.")
      return
    }

    // if (!validateEmail(email)) {
    //   setError("Please provide a valid email")
    //   return
    // }

    try {
      const res = await fetch("/.netlify/functions/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
      if (res.ok !== false) {
        localStorage.removeItem("agencyForm")
        setSuccess(true)
        document.querySelector("form.agency").classList.add("formSubmitted")
        document.querySelector("form.agency button").disabled = true
      } else {
        console.log("")
      }
    } catch (err) {
      console.log(err)
      setError("Something went wrong")
    }
  }

  return (
    <div>
      <div className="common_section_title membership-top-form">
        <h2>
          Agency<span>Membership Inquiry</span>
        </h2>
        <p>
          COMMB Membership Fees: $1,265.00 (+ applicable taxes) for agencies
          with annual media billings in excess of $5MM, $793.00 (+ applicable
          taxes) for agencies with annual media billings under $5MM.
        </p>
      </div>
      <form onSubmit={handleSubmit} className="agency">
        <input
          type="text"
          placeholder="Company Name *"
          className="textInput"
          name="companyName"
          defaultValue={agencyformValuess.companyName && companyName}
          onChange={handleChangeAgency}
        />
        <span className="error_message">
          {!companyName && error ? error : ""}
        </span>
        <Select
          options={formatOptions}
          className={`customSelect custom-option`}
          styles={customStyles}
          classNamePrefix={`contactDropdown`}
          placeholder={`Annual Media Billings over $5MM? *`}
          name="billingsOver5MMM"
          defaultValue={agencyformValuess.billingsFiveMil && billingsFiveMil}
          onChange={onChangeTwoAgency}
        />
        <span className="error_message">
          {!billingsFiveMil && error ? error : ""}
        </span>
        <input
          type="text"
          placeholder="Address *"
          className="textInput"
          name="agencyAddress"
          defaultValue={agencyformValuess.agencyAddress && agencyAddress}
          onChange={handleChangeAgency}
        />
        <span className="error_message">
          {!agencyAddress && error ? error : ""}
        </span>
        <input
          type="text"
          placeholder="City *"
          className="textInput"
          name="city"
          defaultValue={agencyformValuess.city && city}
          onChange={handleChangeAgency}
        />
        <span className="error_message">{!city && error ? error : ""}</span>
        <Select
          options={options}
          className={`customSelect custom-option`}
          styles={customStyles}
          classNamePrefix={`contactDropdown`}
          placeholder={`Province: *`}
          name="province"
          defaultValue={agencyformValuess.province && province}
          onChange={provinceOnChangeAgency}
        />
        <span className="error_message">{!province && error ? error : ""}</span>
        <input
          type="text"
          className="textInput"
          name="postalCode"
          defaultValue={agencyformValuess.postalCode && postalCode}
          onChange={handleChangeAgency}
          placeholder="Postal Code: *"
        />
        <span className="error_message">
          {!postalCode && error ? error : ""}
        </span>
        <input
          type="number"
          placeholder="Phone: *"
          className="textInput"
          name="phone"
          defaultValue={agencyformValuess.phone && phone}
          onChange={handleChangeAgency}
        />
        <span className="error_message">{!phone && error ? error : ""}</span>
        <input
          type="text"
          placeholder="Email: *"
          className="textInput"
          name="email"
          defaultValue={agencyformValuess.email && email}
          onChange={handleChangeAgency}
        />
        <span className="error_message">{!email && error ? error : ""}</span>
        <input
          type="text"
          placeholder="Website: *"
          className="textInput"
          name="website"
          defaultValue={agencyformValuess.website && website}
          onChange={handleChangeAgency}
        />
        <span className="error_message">{!website && error ? error : ""}</span>
        <h4> VOTING REPRESENTATIVE (votes at COMMB's Meeting of Members):</h4>
        <input
          type="text"
          placeholder="Voting Representative Name: "
          className="textInput"
          name="votingRepName"
          defaultValue={agencyformValuess.votingRepName && votingRepName}
          onChange={handleChangeAgency}
        />
        <input
          type="text"
          placeholder="Voting Representative Job Title: "
          className="textInput"
          name="repJobTitle"
          defaultValue={agencyformValuess.repJobTitle && repJobTitle}
          onChange={handleChangeAgency}
        />
        <input
          type="text"
          placeholder="Voting Representative Phone: "
          className="textInput"
          name="repPhoneNumber"
          defaultValue={agencyformValuess.repPhoneNumber && repPhoneNumber}
          onChange={handleChangeAgency}
        />
        <input
          type="text"
          placeholder="Voting Representative Email: "
          className="textInput"
          name="repEmail"
          defaultValue={agencyformValuess.repEmail && repEmail}
          onChange={handleChangeAgency}
        />

        <button className="submit">
          <span className="submitText">Submit</span>
          {success && (
            <span className="thankYouMsg">
              Thank you for contacting us! We'll be in touch with you soon.
            </span>
          )}
        </button>
      </form>
    </div>
  )
}

export default AgencyForm
