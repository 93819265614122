import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import quoteImg from "../../images/quote-icon.svg"

const Testimonial = ({ data }) => {
  const { testimonial } = data
  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <div className="testimonial_section">
      <div className="container">
        <div className="testnominal_slider">
          <Slider {...settings}>
            {testimonial.map(quote => (
              <div className="testimonial_slide" key={quote.id}>
                <div className="quote_img">
                  <img src={quoteImg} alt="quote" />
                </div>
                <div className="test_slide_inner">
                  <div className="t_slide_image">
                    <GatsbyImage image={getImage(quote.image)} alt="slider" />
                  </div>
                  <div className="t_slide_info">
                    <div className="t_slide_info_inner">
                      {renderRichText(quote.testimonial)}
                      <div className="nominate">
                        {" "}
                        <span>{quote.author}</span>
                      </div>
                      <div className="nominate">
                        <span>{quote.designation}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default Testimonial
